import React from "react"

import Header1 from "/src/components/header1"

import PackingList1 from "/src/assets/showcase/packing-list-app-offers-linkfive-subscriptions.png"
import PackingList2 from "/src/assets/showcase/packing-list-special-subscription-offers-with-linkfive.png"
import PackingList3 from "/src/assets/showcase/packing-list-filter-attributes-highlight.png"

export default function ShowcasePackinglist(props) {
    return (
        <>
            <section className="bg-white py-8" id="product">
                <div className="container max-w-6xl mx-auto m-8">
                    <Header1
                        headerText="Packing List App uses LinkFive to offer Subscriptions"
                        widthClass="w-4/6"
                    />
                    <div className="flex flex-wrap mt-20">
                        <div className="sm:w-2/3 p-6">
                            <h3 className="mt-2 mb-8 text-4xl font-extrabold leading-10 tracking-tight text-gray-800">
                                Packing List App uses LinkFive to offer a special limited sale on weekends
                            </h3>
                            <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                                Packing List is your best travel preparation companion.
                                The app has integrated LinkFive's Flutter SDK and offers its subscriptions to its users through our SaaS.
                            </p>

                            <h4 className="max-w-2xl mt-12 text-xl leading-7 text-gray-800">
                                <b>Weekend Special Sale Subscriptions in their App</b>
                            </h4>
                            <p className="max-w-2xl mt-2 text-xl leading-7 text-gray-800">
                                The Packing List team offers their customers regional and weekly deals in the app to find out which subscription sells the most.
                            </p>
                            <p className="max-w-2xl mt-2 text-xl leading-7 text-gray-800">
                                To find out which subscription works best, the Packing List team has created several subscriptions and coordinates the playout of subscriptions with LinkFive.
                            </p>
                        </div>
                        <div className="w-full sm:w-1/3 p-12">
                            <img alt="LinkFive can create your subscription and synchronize them with all app stores"
                                 title="LinkFive Synchronize your subscriptions to all app stores"
                                 style={{maxHeight:"400px"}}
                                 src={PackingList1}/>
                        </div>
                    </div>
                    <div className="flex flex-wrap mt-0">
                        <div className="sm:w-1/2 p-6">
                            <h3 className="mt-2 mb-8 text-4xl font-extrabold leading-10 tracking-tight text-gray-800">
                                How Packing List integrated Special Sale Subscription Offers with LinkFive
                            </h3>

                            <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                                The Flutter SDK provided everything they needed and no additional integration was needed.
                            </p>

                            <h4 className="max-w-2xl mt-12 text-xl leading-7 text-gray-800">
                                <b>Switching subscription offering for certain users</b>
                            </h4>

                            <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                                To switch the subscription offering for certain users, Packing List created a LinkFive Playout Package with the following filters:
                            </p>

                            <ul className="list-disc max-w-2xl mt-4 ml-6 text-xl leading-7 text-gray-800">
                                <li><b>Country:</b> Only show the subscriptions for Germany, Austria</li>
                                <li><b>App Version:</b> Only for versions 1.9.0 and above</li>
                                <li><b>Start Time:</b> The sale should start on Friday, 10AM </li>
                                <li><b>End Time:</b> The sale should end on Sunday, 23:59PM  </li>
                            </ul>


                        </div>
                        <div className="w-full sm:w-1/2 p-12">
                            <img alt="LinkFive can create your subscription and synchronize them with all app stores"
                                 title="LinkFive Synchronize your subscriptions to all app stores"
                                 style={{maxHeight:"600px"}}
                                 src={PackingList2}/>

                        </div>
                    </div>

                    <div className="flex flex-wrap mt-0">
                        <div className="sm:w-1/2 p-6">

                            <h4 className="max-w-2xl mt-12 text-xl leading-7 text-gray-800">
                                <b>Sending JSON data along with your subscriptions</b>
                            </h4>

                            <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                                It is possible to deliver any custom JSON data with your subscriptions.
                                Easily defined in the LinkFive Web-UI and will be transported through our SDK to your App.
                            </p>

                            <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                                <b>That's it.</b>
                            </p>
                            <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                                LinkFive does the rest automatically and delivers the subscriptions including the custom data to users with the applied filter.
                            </p>
                        </div>

                        <div className="w-full sm:w-1/2 p-12">
                            <img alt="LinkFive can create your subscription and synchronize them with all app stores"
                                 title="LinkFive Synchronize your subscriptions to all app stores"
                                 style={{maxHeight:"400px"}}
                                 src={PackingList3}/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
