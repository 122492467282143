import React from "react"

import Header1 from "/src/components/header1"

import Prana1 from "/src/assets/showcase/prana/prana-uses-linkfive-flutter-plugin-for-subscriptions.png"
import HeaderH2 from "../headerH2";

export default function ShowcasePrana(props) {
    return (
        <>
            <section className="bg-white py-8" id="product">
                <div className="container max-w-6xl mx-auto m-8">
                    <HeaderH2
                        headerText="Prana Breathing App uses LinkFive Flutter Plugin"
                        widthClass="w-4/6"
                    />
                    <div className="flex flex-wrap mt-20">
                        <div className="sm:w-2/3 p-6">
                            <h3 className="mt-2 mb-8 text-4xl font-extrabold leading-10 tracking-tight text-gray-800">
                                The Flutter plugin from LinkFive was installed in a few minutes.
                            </h3>
                            <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                                Pranayama (Prāṇāyāma) is the practice of breath control in yoga. In modern yoga as exercise, it consists of synchronising the breath with movements between asanas, but is also a distinct breathing exercise on its own, usually practised after asanas.
                            </p>

                            <h4 className="max-w-2xl mt-12 text-xl leading-7 text-gray-800">
                                <b>The app is built with Flutter and integrated the latest Flutter subscription plugin from LinkFive.</b>
                            </h4>
                            <p className="max-w-2xl mt-2 text-xl leading-7 text-gray-800">
                                We switched from RevenueCat to LinkFive because a few functions were missing. The LinkFive team helped us very well with the switch. The integration only took a few minutes.
                            </p>
                        </div>
                        <div className="w-full sm:w-1/3 p-12">
                            <img alt="LinkFive can create your subscription and synchronize them with all app stores"
                                 title="LinkFive Synchronize your subscriptions to all app stores"
                                 style={{maxHeight:"400px"}}
                                 src={Prana1}/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
