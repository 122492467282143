import React from "react"

import BottomWave from "/src/assets/BottomWave.svg"
import DevelopmentImage from "/src/assets/undraw_development_re_g5hq.svg"

export default function ShowcaseHeader(props) {
    return (
        <>
            <div className="py-24 bg-l6-background">
                <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center lg:mb-0">
                    <div className="md:ml-14 mb-10 md:mb-0 md:pt-16 md:max-w-2xl md:w-full flex flex-col items-start">
                        <h1 className="text-4xl font-extrabold leading-10 tracking-tight sm:text-5xl sm:leading-none md:text-6xl">
                            LinkFive Flutter App Showcases
                        </h1>

                        <h2 className="mb-5 mt-2 text-base leading-normal sm:text-lg sm:max-w-xl md:text-xl md:mx-0">
                            Our customers are already using LinkFive as their subscription management system in their mobile apps.
                            We have collected a few examples of how LinkFive is used in their production applications.
                        </h2>
                    </div>

                    <div className="w-full md:w-2/5 py-6 lg:ml-auto mx-auto xl:block hidden">
                        <DevelopmentImage className="w-full md:w-4/5 z-50 lg:mx-auto"/>
                    </div>
                </div>
            </div>
        </>
    )
}
