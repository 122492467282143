import React from "react"
import Layout from "../components/layout"

import ShowcaseHeader from "../components/showcase/showcase-header";
import ShowcasePackinglist from "../components/showcase/showcase-packinglist";
import {GatsbySeo} from "gatsby-plugin-next-seo/src/meta/gatsby-seo";
import ShowcaseCalistree from "../components/showcase/showcase-calistree";
import ShowcasePrana from "../components/showcase/showcase-prana";

export default function Showcase() {
  return (
    <>
      <GatsbySeo title="Flutter Apps Showcase"
                 description={`Our customers are already using LinkFive as their subscription management system in their mobile apps. We have collected a few examples of how LinkFive is used in their production applications.`}
      />
      <Layout fullWave="false" bottomWave="false" callToAction="true" utmSource="showcase">
        <ShowcaseHeader/>
        <div className="pb-12" />
        <ShowcasePrana />
        <div className="pb-24" />
        <ShowcasePackinglist/>
      </Layout>
    </>
  )
}
